<template>
  <div class="permissions-page">
    <layout v-load="loading">
      <template #header>
        <h1 class="title-1">Permissions</h1>
      </template>
      <b-modal
          :id="modalId"
          :title="modalTitle"
          centered
          title-class="font-16"
          hide-footer
          @hide="handleHiddenModal"
      >
        <b-form @submit="handleSubmit">
          <b-form-group
              label="User:"
              label-for="user"
              class="required"
              v-if="formMode === FORM_MODE.CREATE"
          >
            <b-form-select
                id="user"
                v-model="form.user"
                :options="list"
                text-field="username"
                value-field="id"
                placeholder="Enter a user..."
            />
          </b-form-group>
          <b-form-group label="Role:" label-for="role" class="required">
            <b-form-select
                id="role"
                v-model="form.role"
                :options="roles"
                placeholder="Enter a role..."
                required
            />
          </b-form-group>

          <div class="d-flex justify-content-end">
            <b-button type="submit" variant="primary">
              <i class="bx bx-save"></i>
              {{ modalButtonText }}
            </b-button>
          </div>
        </b-form>
      </b-modal>
      <div class="permissions-page__wrapper">
        <div class="permissions-page__content">
          <div class="permissions-page__top">
            <h3 class="mb-0">
              Identity and Access Management
            </h3>
            <b-button
                variant="primary"
                class="btn btn-primary permissions-page__button"
                @click="handleAddButtonClick"
            >
              <i class="bx bx-plus-medical"></i>
              Add user
            </b-button>
          </div>
          <div
              v-if="listFiltred.length > 0"
              class="table-responsive table-fixed-header"
          >
            <table class="table table-hover mb-0">
              <thead class="thead-light">
              <tr>
                <th class="table__index-column" style="width: 30px">#</th>
                <th>Name</th>
                <th>Email</th>
                <th>Role</th>
                <th class="text-right">Actions</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in listFiltred" :key="item.id">
                <td class="table__index-column" style="width: 30px">
                  {{ index + 1 }}
                </td>
                <td>{{ item.firstName }} {{ item.lastName }}</td>
                <td>{{ item.email }}</td>
                <td>
                  {{ item.roles.join(", ") }}
                </td>
                <td style="width: 70px">
                  <div class="actions-buttons">
                    <i
                        class="permissions-page__icon mr-3 permissions-page__icon_edit"
                        @click="handleEditIconClick(item)"
                    />
                    <i
                        class="permissions-page__icon permissions-page__icon_delete"
                        @click="handleDeleteIconClick(item.id)"
                    />
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div v-else>
            <h3>Empty</h3>
          </div>
        </div>
      </div>
    </layout>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  data() {
    return {
      FORM_MODE: {
        EDIT: "edit",
        CREATE: "create",
      },
      modalId: "user-modal",
      formMode: "create",
      form: {
        user: "",
        role: "",
      },
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;
    await this.$store.dispatch("permissions/getUsers");
    await this.$store.dispatch("permissions/getRoles");
    this.loading = false;
  },
  computed: {
    modalTitle() {
      return this.formMode === this.FORM_MODE.EDIT ? "Edit role" : "Add user";
    },
    modalButtonText() {
      return this.formMode === this.FORM_MODE.EDIT ? "Save" : "Create";
    },
    ...mapState({
      list: (state) => state.permissions.users,
      roles: (state) => state.permissions.roles,
    }),
    listFiltred() {
      return this.list.filter((item) => item.roles?.length);
    },
  },
  methods: {
    handleEditIconClick(tag) {
      this.form.user = tag.id;
      this.form.role = tag.roles[0];
      this.formMode = this.FORM_MODE.EDIT;
      this.$bvModal.show(this.modalId);
    },
    handleDeleteIconClick(id) {
      this.$bvModal
          .msgBoxConfirm("Please confirm that you want to delete this user", {
            title: "Please Confirm",
            okVariant: "danger",
            okTitle: "Yes",
            cancelTitle: "No",
            footerClass: "p-2",
            hideHeaderClose: false,
            centered: true,
          })
          .then((value) => {
            if (value) {
              const formData = new FormData();
              formData.append("roles[]", "");
              this.$store.dispatch("permissions/deleteRoles", id);
            }
          });
    },
    handleAddButtonClick() {
      this.formMode = this.FORM_MODE.CREATE;
      this.$bvModal.show(this.modalId);
    },
    handleHiddenModal() {
      this.form.user = "";
      this.form.role = "";
    },
    handleSubmit(event) {
      event.preventDefault();
      const formData = new FormData();
      formData.append("roles[0]", this.form.role);
      this.$store.dispatch("permissions/updateUser", {
        id: this.form.user,
        field: formData,
      });
      this.$bvModal.hide(this.modalId);
    },
  },
};
</script>

<style lang="scss" scoped>
.permissions-page {
  &__wrapper {
    padding: 20px 50px;
    overflow-y: auto;
    -ms-overflow-style: none;
    flex-grow: 1;
    &::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
    }

    /* ползунок скроллбара */
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.05);
      border-radius: 100px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: rgba(26, 22, 22, 0.05);
    }
  }
  &__content {
    max-width: 700px;
    padding: 20px;
    background-color: #fff;
    box-shadow: 0 0.75rem 1.5rem rgb(18 38 63 / 3%);
    border-radius: 4px;
  }
  &__title {
    margin-bottom: 2px;
    font-weight: 500;
    font-size: 23px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: #1b212d;
  }
  &__top {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
  }
  &__button {
    margin-left: auto;
  }
  &__icon {
    cursor: pointer;
    &::before {
      content: "";
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: 50%;
      background-size: 20px;
      display: inline-block;
      vertical-align: top;
    }
    &_edit::before {
      background-image: url("~@/assets/images/icons/ic-pencil.svg");
    }
    &_delete::before {
      background-image: url("~@/assets/images/icons/ic-delete.svg");
    }
  }
}
</style>
